require('./bootstrap');

require('./carousel');
// Tarteaucitrion.io (cookies gestion)
require('./tarteaucitron');

if (document.getElementById('formAnalysePrenom')) {

    require('./analyse-prenoms');
}

if (document.getElementById('formTestAmoureux')) {
    require('./test-amoureux');
}
if (document.getElementById("resultatGen")) {
    webmaster = require('./webmaster');
    webmaster().chargement();

}


