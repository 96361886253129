
document.getElementById('formTestAmoureux').onsubmit= function(e){
    e.preventDefault();
}
$('#resultat').hide();

$('#btnTestAmoureux').click(function() {
    if ($('#formTestAmoureux')[0].checkValidity()) {
        $('#chargement').show();
        $('#resultat').hide();
        setTimeout(function () {
            $.ajax({
                type: "POST",
                url: "ajax/test-amoureux",
                data: $('#formTestAmoureux').serialize(), // serializes the form's elements.
                success: function (data) {
                    $('#resultat_pourcentage').html(data.pourcentage + "%");
                    $('#resultat_noms').html(data.nom1 + " & " + data.nom2);
                    $('#resultat_phrase').html(data.phrase);

                    if (data.signe1 == 0 || data.signe2 == 0) {
                        $('#resultat_avertissement').show(500);
                    } else {
                        $('#resultat_avertissement').hide();
                    }
                    $('#chargement').hide();
                    $('#resultat').show();
                }
            });
        }, 1000);
    }

});
