module.exports = function () {
    return {
        changeCouleur: function (input, divCoulNom) {
            var divCoul = document.getElementById(divCoulNom);

            if (divCoul && input) {
                var couleur;
                couleur = '#' + input.val();
                divCoul.style.backgroundColor = couleur;
            }
        },


        generationCode: function () {
            //if (verifForm()) {
            var form = document.forms['formGeneration'];
            var montextArea = document.getElementById("codeGenere");
            var divResultat = document.getElementById("resultatGen");

            var inputFond = form.elements["couleurFond"];
            var inputLiens = form.elements["couleurLiens"];
            var inputTitre = form.elements["couleurTitre"];
            var inputTexte = form.elements["couleurTexte"];

            var selectFormat = form.elements["selectFormat"];
            var width;
            var height;
            switch (selectFormat.value) {
                case "1":
                    width = 800;
                    height = 350;
                    break;
                case "2":
                    width = 125;
                    height = 400;
                    break;
                case "3":
                    width = 300;
                    height = 200;
                    break;
                default:
                    break;
            }


            var script = '<div style="width:' + width + 'px;">';
            script = script + '<iframe name="horoscope-fr.info" SCROLLING="no" frameborder="0" width="' + width + 'px" height="' + height + 'px" src="' + URL_SITE + '/webmasterhoroscope.php?';
            script = script + 'coulFond=' + inputFond.value;
            script = script + '&coulTexte=' + inputTexte.value;
            script = script + '&coulTitre=' + inputTitre.value;
            script = script + '&coulLiens=' + inputLiens.value;
            script = script + '&selectFormat=' + selectFormat.value;
            script = script + '"></iframe><br><div align="center" style="font-size:8px;"><a href="' + URL_SITE + '" title="Horoscope du jour"' +
                ' style="color:FF7F50; text-decoration:none; font-weight:normal;width:' + width + 'px;\n' +
                ' font-family: Verdana, Arial;">Horoscope du jour</a> avec Horoscope-fr.info</div></div>';

            montextArea.value = script;
            divResultat.innerHTML = script;

            //}

        },


        setSelection: function (textarea) {
            var taille = textarea.value.length;
            textarea.setSelectionRange(0, taille);
        },


        chargement: function () {

            var inputFond = $('#couleurFond');
            webmaster().changeCouleur(inputFond, 'divFond');
            inputFond.change(function () {
                webmaster().changeCouleur(inputFond, 'divFond')
            });
            var inputLiens = $('#couleurLiens');
            webmaster().changeCouleur(inputLiens, 'divLiens');
            inputLiens.change(function () {
                webmaster().changeCouleur(inputLiens, 'divLiens')
            });

            var inputTitre =$('#couleurTitre');
            webmaster().changeCouleur(inputTitre, 'divTitre');
            inputTitre.change(function () {
                webmaster().changeCouleur(inputTitre, 'divTitre')
            });
            var inputTexte = $('#couleurTexte');
            webmaster().changeCouleur(inputTexte, 'divTexte');
            inputTexte.change(function () {
                webmaster().changeCouleur(inputTexte, 'divTexte')
            });
            webmaster().generationCode();

        }
    }
};



