
document.getElementById('formAnalysePrenom').onsubmit= function(e){
    e.preventDefault();
}

$('#btnAnalyserPrenom').click(function() {
    if ($('#formAnalysePrenom')[0].checkValidity()) {
        $('#chargement').show();
        $('#resultat').hide();
        setTimeout(function () {
            $.ajax({
                type: "POST",
                url: "ajax/analyse-prenoms",
                data: $('#formAnalysePrenom').serialize(), // serializes the form's elements.
                success: function (data) {
                    $('#resultat').html(data);
                    $('#chargement').hide();
                    $('#resultat').show();
                }
            });
        }, 2000);
    }
});
